
import { defineComponent, ref, reactive, watch } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import { commafy } from "xe-utils";
import { helper as $h } from "@/utils/helper";
import { VxeFormProps } from "vxe-table";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue";
import { remove } from "lodash";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
    AddressSelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const isMemberBanned = ref(false);
    watch(
      () => grid.value.editingRow?.Id,
      async id => {
        if (!id) {
          isMemberBanned.value = false;
          return;
        }

        const resp = await model?.dispatch("ban/query", {
          condition: new Condition("IsReleaseNow", Operator.Equal).and(
            "MemberId",
            Operator.Equal,
            id
          )
        });
        isMemberBanned.value = resp.length > 0;
      }
    );

    const gridOptions: GridOptions = {
      title: "會員資訊",
      canCreate: true,
      canUpdate: true,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true
      },
      columns: [
        {
          field: "Number",
          title: "會員編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Person.Name",
          title: "會員名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Person.MobilePhone",
          title: "手機",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.MemberStatus).find(
                  e => e.Value === cellValue
                )?.Name
              : undefined
        },
        {
          field: "BanCount",
          title: "黑名單次數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          title: "交易紀錄",
          headerAlign: "center",
          slots: { default: "grid-record" }
        },
        {
          title: "操作",
          width: "200",
          headerAlign: "center",
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("member/query", params)
          : undefined,
        queryAll: model ? () => model.dispatch("member/query") : undefined,
        save: model
          ? params => model.dispatch("member/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 110,
      titleAlign: "right",
      items: [
        {
          field: "Photo.Uri",
          title: "",
          span: 24,
          slots: { default: "column-photo" }
        },
        {
          field: "Person.Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "姓名" } }
        },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          itemRender: {
            name: "$select",
            props: { placeholder: "狀態" },
            options: model
              ? Object.values(model.enums.MemberStatus).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        // { field: "Account", title: "帳號", span: 12, itemRender: { name: "$input", props: { placeholder: "帳號", disabled: true } } },
        {
          field: "Email",
          title: "Email",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "Email" } }
        },
        {
          field: "ChangeEmail",
          title: "新Email",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "如有變更Email, 將會顯示於此",
              disabled: true
            }
          }
        },
        {
          field: "Password",
          title: "密碼",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "password",
              placeholder: "密碼",
              autocomplete: "new-password"
            }
          }
        },
        {
          field: "Person.MobilePhone",
          title: "手機",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "手機" } }
        },
        {
          field: "Person.Nickname",
          title: "暱稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "名稱" } }
        },
        {
          field: "Person.Sex",
          title: "性別",
          span: 12,
          itemRender: {
            name: "$select",
            props: { placeholder: "性別" },
            options: model
              ? Object.values(model.enums.Sex).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        {
          field: "Person.BirthDate",
          title: "生日",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "生日", type: "date" }
          }
        },
        // {
        //   field: "LvId",
        //   title: "等級",
        //   span: 12,
        //   slots: { default: "column-lv-id" }
        // },
        {
          field: "Person.Address",
          title: "地址",
          span: 24,
          slots: { default: "column-address" }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: { name: "$textarea" }
        }
      ],
      rules: {
        "Person.Name": [{ required: true }],
        Email: [
          {
            required: true,
            validator: params => {
              if (
                !params.itemValue ||
                CloudFun.utils.validator.validateEmail(params.itemValue)
              )
                return new Error("Email格式錯誤");
            }
          }
        ],
        "Person.MobilePhone": [
          {
            required: true,
            validator: params => {
              if (
                !params.itemValue ||
                CloudFun.utils.validator.validatePhoneNumber(params.itemValue)
              )
                return new Error("手機格式錯誤");
            }
          }
        ],
        Account: [{ required: true }],
        Password: [
          {
            required: false,
            validator: params => {
              if (params.itemValue) {
                const regex = new RegExp(
                  "^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)"
                );
                if (!regex.test(params.itemValue))
                  return new Error("須8碼以上含大小寫英文、數字");
              } else if (!params.data.Id) return new Error("新用戶須設定密碼");
            }
          }
        ],
        LvId: [{ required: true }],
        "Person.BirthDate": [
          {
            required: false,
            validator: params => {
              if (
                params.itemValue &&
                new Date(params.itemValue).getTime() > new Date().getTime()
              )
                return new Error("生日必須小於今天");
            }
          }
        ]
      }
    };

    const gradeSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇會員等級",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value => model!.dispatch("memberGrade/find", value), // eslint-disable-line
        query: params => model!.dispatch("memberGrade/query", params) // eslint-disable-line
      }
    };

    const banModal = reactive({
      current: {} as any,
      visible: false,
      formOptions: {
        titleWidth: 110,
        titleAlign: "right",
        items: [
          {
            field: "StartTime",
            title: "開始時間",
            span: 12,
            itemRender: {
              name: "$input",
              props: { type: "datetime", placeholder: "請輸入時間" }
            }
          },
          {
            field: "EndTime",
            title: "結束時間",
            span: 12,
            itemRender: {
              name: "$input",
              props: { type: "datetime", placeholder: "請輸入時間" }
            }
          },
          {
            field: "Description",
            title: "事由",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                placeholder: "請輸入事由"
              }
            }
          }
        ]
      },
      show() {
        banModal.visible = true;
        banModal.current.MemberId = grid.value.editingRow.Id;
        banModal.current.StartTime = new Date();
        banModal.current.EndTime = new Date();
        banModal.current.EndTime.setMonth(
          banModal.current.EndTime.getMonth() + 1
        );
      },
      submit() {
        if (!banModal.current.MemberId) return;
        model!.dispatch("ban/insert", banModal.current).then(() => {
          banModal.current = {};
          grid.value.refresh();
          banModal.visible = false;
          grid.value.isModalPopup = false;
        });
      },
      async unbanned() {
        if (!grid.value.editingRow.Id) return;
        const ban = (
          await model!.dispatch("ban/query", {
            condition: new Condition(
              "MemberId",
              Operator.Equal,
              grid.value.editingRow.Id
            )
          })
        )[0];

        await model!.dispatch("ban/delete", ban.Id);
        grid.value.refresh();
        grid.value.isModalPopup = false;
      }
    });

    const orderGrid = ref<any>();
    const orderModal = reactive({
      visible: false,
      memberId: "",
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canRead: true,
        canDelete: false,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true
        },
        columns: [
          {
            field: "Time",
            title: "消費時間",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            formatter: ({ cellValue }) =>
              $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
          },
          {
            field: "Number",
            title: "訂單編號",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "Amount",
            title: "金額",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            align: "right",
            formatter: ({ cellValue }) => commafy(cellValue)
          }
        ],
        promises: {
          query: model
            ? params => {
                params.condition!.and(
                  "Member.Id",
                  Operator.Equal,
                  orderModal.memberId
                );
                if (!params.sortings) params.sortings = [];
                params.sortings.push({ column: "Time", order: 1 });
                return model.dispatch("order/query", params);
              }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%" }
      } as GridOptions,
      show(id: string) {
        orderModal.visible = true;
        orderModal.memberId = id;
        if (orderGrid.value) orderGrid.value.refresh();
      },
      edit(row: any, callback: any) {
        orderDetailModal.orderId = row.Id;
        if (orderDetailGrid.value) orderDetailGrid.value.refresh();
        callback();
      }
    });

    const orderDetailGrid = ref<any>();
    const orderDetailModal = reactive({
      visible: false,
      orderId: "",
      gridOptions: {
        canCreate: false,
        canUpdate: false,
        canDelete: false,
        canRead: false,
        multiselect: false,
        toolbarConfig: {
          custom: false,
          refresh: true
        },
        columns: [
          {
            field: "Name",
            title: "商品",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "Product.Number",
            title: "品號",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            field: "SalePrice",
            title: "售價",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true,
            align: "right",
            formatter: ({ cellValue }) => commafy(cellValue)
          },
          {
            field: "Quantity",
            title: "數量",
            showHeaderOverflow: true,
            showOverflow: true,
            sortable: true
          },
          {
            title: "金額",
            align: "right",
            formatter: ({ row }) =>
              commafy(parseInt(row.Quantity) * parseInt(row.SalePrice))
          }
        ],
        promises: {
          query: model
            ? params => {
                params.condition!.and(
                  "OrderId",
                  Operator.Equal,
                  orderDetailModal.orderId
                );

                return new Promise(resolve => {
                  model.dispatch("orderItem/query", params).then(async res => {
                    console.log(
                      "🚀 ~ file: Main.vue:788 ~ model.dispatch ~ res",
                      res
                    );
                    // const products = await model.dispatch("product/query", {
                    //   condition: new Condition(
                    //     "Id",
                    //     Operator.In,
                    //     res.data.map((e: any) => e.ProductId)
                    //   )
                    // });
                    res.data.forEach((e: any) => {
                      if (e.Product) e.Name = e.Product.Name;
                    });
                    resolve(res);
                  });
                });
              }
            : undefined
        },
        modalConfig: { width: "80%", height: "80%" }
      } as GridOptions
    });

    const detailGrid = ref<any>({});

    const bonusGrid = ref<any>();
    const bonusModal = reactive({
      visible: false,
      accountBook: {} as any,
      gridOptions: {
        mode: "inline",
        canCreate: true,
        canUpdate: true,
        canRead: false,
        canDelete: false,
        multiselect: false,
        // toolbarConfig: {
        //   custom: false,
        //   refresh: true
        // },
        columns: [
        {
          field: "Time",
          title: "時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 160,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        { field: 'Name', title: '名目', showHeaderOverflow: true, showOverflow: true, sortable: true, editRender: { name: "$input", immediate: true } },
        { field: "BonusPoints", title: "紅利點數", showHeaderOverflow: true, showOverflow: true, align: "right", width: 150, sortable: true, editRender: { name: "$input", immediate: true, props: { type: "number" } }, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "Amount", title: "消費金額", showHeaderOverflow: true, showOverflow: true, align: "right", width: 150, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) },
        { field: "BonusBalance", title: "紅利結餘", showHeaderOverflow: true, showOverflow: true, align: "right", width: 180, sortable: true, formatter: ({ cellValue }) => CloudFun.utils.formatMoney(cellValue) }
        ],
        editRules: {
        Name: [{ required: true, message: "未輸入文字" }],
        BonusPoints: [{ required: true, message: '未輸入單價' }],
        },
        promises: {
          query: model
            ? params => {
                if (!bonusModal.accountBook.Id) return undefined;
                params.condition!.and(
                  "AccountBookId",
                  Operator.Equal,
                  bonusModal.accountBook.Id
                );
                if (!params.sortings) params.sortings = [];
                params.sortings.push({ column: "Time", order: 1 });
                return model.dispatch("memberAccountBookItem/query", params);
              }
            : undefined,
          queryAll: model ? () => model.dispatch("memberAccountBookItem/query") : undefined,
          save: model ? (params) => model.dispatch("memberAccountBookItem/save", params).then(async () => { bonusModal.accountBook.BonusPoints = (await model.dispatch('memberAccountBook/find', bonusModal.accountBook.Id)).BonusPoints; bonusGrid.value.refresh(); }) : undefined,
        },
        modalConfig: { width: "80%", height: "80%", showFooter: true }
      } as GridOptions,
      async show(id: string) {
        bonusModal.visible = true;
        bonusModal.accountBook = (
          await model?.dispatch("memberAccountBook/query", {
            condition: new Condition("MemberId", Operator.Equal, id)
          })
        )[0] ?? { BonusPoints: 0 };
        if (bonusGrid.value) { bonusGrid.value.refresh(); }
      }
    });

    return {
      grid,
      isMemberBanned,
      gridOptions,
      formOptions,
      gradeSelectOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      banModal,
      orderGrid,
      orderModal,
      bonusGrid,
      bonusModal,
      orderDetailGrid,
      orderDetailModal
    };
  },
  methods: {
    onFormSubmit(row: any, submit: any) {
      row.Lv = row.Lv || {};
      row.Lv.Id = row.LvId;
      // row.Email = row.Person.Email;
      row.Nickname = row.Person.Nickname;
      submit();
    },
    async onAddItem() {
      // console.log(masterRow.Id);
      // if (!masterRow.Id) {
      //   if (!confirm("新增項目須先建立此張調整單，是否同意?")) return;
      //   try {
      //     const bill = await this.$model.dispatch("memberAccountBookItem/insert", masterRow);
      //     Object.assign(masterRow, bill);
      //   } catch (e: any) {
      //     this.$send('error', e);
      //   }
      // }
      this.bonusGrid.addNewRow({
        AccountBookId: this.bonusModal.accountBook.Id,
      });
    },
  }
});
